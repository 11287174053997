<template>
  <div id="app">
    <Grid />
  </div>
</template>
<script>
import Grid from '@/components/Grid';

export default {
  name: 'App',
  components: {
    Grid,
  },
  data: () => ({    
   
  }),
};
</script>
